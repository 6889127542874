import { Orders } from '../../services/http/Orders'
import router from '../../router/index'
import { Errors } from '../../errors'
import i18n from '../../i18n'
import { LocalStorage } from '../../services/storage/localStorage'

const PUSH_AVAILABILITY_EVENT = 'analytics/pushAvailabilityEvent'
const getDate = (string) => {
  const date = new Date(string)
  return date.getDate() + '/' + ('0' + (date.getMonth() + 1)).slice(-2) + '/' + date.getFullYear()
}

const getModalParam = (params) => {
  const payload = {
    name: 'ModalOrderError',
    allowClose: params.allowClose || false,
    size: 490,
    slots: params.slots,
    props: {
      hasButton: params.buttons,
      hasIcon: params.icon
    }
  }

  return payload
}

const scrollToTop = (route) => {
  document.querySelector('#app').scrollTop = 0
  router.push({ name: route })
}

const cancelationRejected = (params) => {
  const { props } = params['rootState'].modal.component
  props[0].submit = true
  props[0].isDisabled = false
  props[0].submitMessage = i18n.t(`message.${params.submitMessage}`)

  return props
}

const cancelationFormValidation = (params) => {
  const { props } = params['rootState'].modal.component
  props[0].isDisabled = false
  props[0].hasSpinner = false
  props[0].hasForm.freeText.validationError = true
  props[0].hasForm.freeText.validationErrorMessage = i18n.t(`message.${params.submitMessage}`)

  return props
}

const EMPTY_ORDER = {
  ID: '',
  lockState: false,
  resultsOut: false,
  isFilterChange: false,
  buttonState: {
    search: {
      isDisabled: false
    },
    order: {
      isDisabled: true
    }
  },
  timeInfo: {
    date: '',
    time: 'MORNING'
  },

  addressInfo: {
    address: '',
    postalCode: {
      value: '',
      isValid: ''
    },
    extraAddress: ''
  },

  additionalInfo: {
    info: ''
  },

  paymentInfo: {
    payment: 'CASH'
  },

  amountInfo: {
    ID: '',
    type: '',
    unitPrice: '',
    totalPrice: '',
    quantity: '',
    isRecommended: false,
    filter: 'LITERS',
    filterField: ''
  },

  provider: {
    name: '',
    email: '',
    phone: ''
  },

  voucher: {
    code: '',
    isValid: '',
    description: '',
    type: '',
    error: {
      type: '',
      value: ''
    },
    canUseIt: false,
    legalBasisUrl: '',
    memberGetMemberCode: ''
  },

  card: {
    type: 'TRAVEL_CLUB'
  },

  deletedReason: ''
}

export const orders = {
  namespaced: true,

  state: {
    // As we need a brand new object, a deep copy of is needed EMPTY_ORDER
    order: JSON.parse(JSON.stringify(EMPTY_ORDER)),

    response: [],

    wayletAvailable: false,

    history: {
      filters: [
        { value: 'ALL', translate: 'ALL' },
        { value: 'ACTIVATED', translate: 'ACTIVATED' },
        { value: 'DELIVERED', translate: 'DELIVERED' },
        { value: 'CANCELLED', translate: 'CANCELLED' }
      ],
      response: []
    },

    historyHome: {
      response: []
    }
  },

  mutations: {
    setID: (state, payload) => {
      state.order.ID = payload
    },

    setAddressInfo: (state, payload) => {
      state.order.addressInfo.address = payload.address
      state.order.addressInfo.postalCode.value = payload.postalCode
    },

    setAmountInfo: (state, payload) => {
      state.order.amountInfo.ID = payload.id
      state.order.amountInfo.type = payload.type
      state.order.amountInfo.unitPrice = payload.unitPrice
      state.order.amountInfo.totalPrice = payload.totalPrice
      state.order.amountInfo.isRecommended = payload.isRecommended
      state.order.amountInfo.quantity = payload.quantity
    },

    setQuantityInfo: (state, payload) => {
      state.order.amountInfo.quantity = payload.quantity
    },

    setDateInfo: (state, payload) => {
      state.order.timeInfo.date = payload.date
    },

    setTimeInfo: (state, payload) => {
      state.order.timeInfo.time = payload.time
    },

    setPaymentInfo: (state, payload) => {
      state.order.paymentInfo.payment = payload.payment
    },

    setExtraAddress: (state, payload) => {
      state.order.addressInfo.extraAddress = payload.extraAddress
    },

    setExtraInfo: (state, payload) => {
      state.order.additionalInfo.info = payload.info
    },

    setResponse: (state, payload) => {
      state.response = payload
    },

    setUnitPrice: (state, payload) => {
      state.order.amountInfo.unitPrice = payload
    },

    setTotalPrice: (state, payload) => {
      state.order.amountInfo.totalPrice = payload
    },

    setProvider: (state, payload) => {
      state.order.provider = payload
    },

    setMemberGetMemberCode: (state, payload) => {
      state.order.memberGetMemberCode = payload
    },

    clearOrder: (state) => {
      // As we need a brand new object, a deep copy of is needed EMPTY_ORDER
      state.order = JSON.parse(JSON.stringify(EMPTY_ORDER))
      state.response = []
    },

    setPostalCodeValidation: (state, payload) => {
      state.order.addressInfo.postalCode.isValid = payload.isValid
    },

    setVoucher: (state, payload) => {
      state.order.voucher.code = payload.code
    },

    setVoucherValidation: (state, payload) => {
      state.order.voucher.isValid = payload.isValid
    },

    setLockState: (state, payload) => {
      state.order.lockState = payload.state
    },

    setResultsOut: (state, payload) => {
      state.order.resultsOut = payload.state
    },

    setButtonState: (state, payload) => {
      state.order.buttonState[payload.type].isDisabled = payload.isDisabled
    },

    setVoucherDescription: (state, payload) => {
      state.order.voucher.description = payload.description
    },

    setVoucherUse: (state, payload) => {
      state.order.voucher.canUseIt = payload.use
    },

    setOrderFilter: (state, payload) => {
      state.order.amountInfo.filter = payload.value
    },

    setFilterField: (state, payload) => {
      state.order.amountInfo.filterField = payload.filterField
    },

    setFilterChange: (state, payload) => {
      state.order.isFilterChange = payload.state
    },

    setHistory: (state, payload) => {
      state.history.response = payload
    },

    setHistoryHome: (state, payload) => {
      state.historyHome.response = payload
    },

    setVoucherError: (state, payload) => {
      state.order.voucher.error.type = payload.type
      state.order.voucher.error.value = payload.value
    },

    setVoucherType: (state, payload) => {
      state.order.voucher.type = payload.type
    },

    setCardNumber: (state, payload) => {
      if (payload.number || payload.control) {
        state.order.card.number = payload.number
        state.order.card.control = payload.control
      } else {
        delete state.order.card.number
        delete state.order.card.control
      }
    },

    setLegalBasisUrl: (state, payload) => {
      state.order.voucher.legalBasisUrl = payload.legalBasisUrl
    },

    setDeleteReason: (state, payload) => {
      state.order.deletedReason = payload.extraText
    },

    setWayletAvailable: (state, payload) => {
      state.wayletAvailable = payload
    }
  },

  actions: {
    getPrices: ({ commit, rootState }, payload) => {
      return Orders.getPrices(payload)
        .then((res) => {
          commit('setAddressInfo', payload)
          commit('setQuantityInfo', { quantity: payload.amount })
          return res.data
        })
        .catch((res) => res)
    },

    setAddress: ({ commit }, payload) => {
      commit('setAddressInfo', payload)
    },

    setQuantity: ({ commit }, payload) => {
      commit('setQuantityInfo', payload)
    },

    setID: ({ commit }, payload) => {
      commit('setID', payload)
    },

    setAmount: ({ commit }, payload) => {
      commit('setAmountInfo', payload)
    },

    setDate: ({ commit }, payload) => {
      commit('setDateInfo', payload)
    },

    setTime: ({ commit }, payload) => {
      commit('setTimeInfo', payload)
    },

    setPayment: ({ commit }, payload) => {
      commit('setPaymentInfo', payload)
    },

    setUnitPrice: ({ commit }, payload) => {
      commit('setUnitPrice', payload)
    },

    setTotalPrice: ({ commit }, payload) => {
      commit('setTotalPrice', payload)
    },

    setExtraAddress: ({ commit }, payload) => {
      commit('setExtraAddress', payload)
    },

    setExtraInfo: ({ commit }, payload) => {
      commit('setExtraInfo', payload)
    },

    setOrder: ({ commit }, payload) => {
      return Orders.setOrder(payload)
        .then((res) => {
          return res.data
        })
        .catch((res) => {
          scrollToTop('server-error')
        })
    },

    setOrderDraft: ({ rootState }, payload) => {
      return Orders.setOrderDraft(payload)
        .then((res) => {
          return res.data
        })
        .catch((res) => {
          scrollToTop('server-error')
        })
    },

    getOrderDraft: ({ rootState }, payload) => {
      return Orders.getOrderDraft(payload)
        .then((res) => {
          return res.data
        })
        .catch((res) => {
          scrollToTop('server-error')
        })
    },

    setProvider: ({ commit }, payload) => {
      commit('setProvider', payload)
    },

    setMemberGetMemberCode: ({ commit }, payload) => {
      commit('setMemberGetMemberCode', payload)
    },

    setResponse: ({ commit }, payload) => {
      commit('setResponse', payload)
    },

    clearOrder: ({ commit }) => {
      commit('clearOrder')
    },

    availableResponse: ({ dispatch, commit }, payload) => {
      dispatch(
        PUSH_AVAILABILITY_EVENT,
        { action: 'gasoleo - si disponible', availability: 'si disponible' },
        { root: true }
      )
      commit('setResponse', payload)
      router.push({ name: 'results' })
    },

    setWayletAvailable: ({ commit }, payload) => {
      commit('setWayletAvailable', payload)
    },

    [Errors.PROVIDER_ERROR]: ({ dispatch }) => {
      dispatch(
        PUSH_AVAILABILITY_EVENT,
        { action: 'gasoleo - no disponible - buscador', availability: 'no disponible - zona' },
        { root: true }
      )
      router.push({ name: 'results-error' })
    },

    [Errors.RESULTS_ERROR]: ({ dispatch }) => {
      dispatch(
        PUSH_AVAILABILITY_EVENT,
        { action: 'gasoleo - no disponible - buscador', availability: 'no disponible - litros' },
        { root: true }
      )
      router.push({ name: 'results-error' })
    },

    [Errors.SERVER_ERROR]: () => scrollToTop('server-error'),

    [Errors.MAX_REQUESTS_LIMIT]: ({ dispatch }, extraInfo) => {
      dispatch('modal/setModalDisplay', { state: true }, { root: true })
      dispatch(
        'modal/setModalComponent',
        getModalParam({
          allowClose: true,
          slots: [
            {
              name: 'title',
              content: i18n.t(`message.REQUEST_LIMIT_TITLE`)
            },
            {
              name: 'description',
              content: i18n.t(`message.REQUEST_LIMIT_DESCRIPTION`, { extraInfo: getDate(extraInfo.expiresAt) })
            }
          ],
          buttons: [
            {
              action: 'close',
              label: i18n.t(`message.REQUEST_LIMIT_BUTTON_LABEL`),
              type: 'button'
            }
          ]
        }),
        { root: true }
      )
    },

    [Errors.DAILY_REQUESTS_LIMIT]: ({ dispatch }, extraInfo) => {
      dispatch('modal/setModalDisplay', { state: true }, { root: true })
      dispatch(
        'modal/setModalComponent',
        getModalParam({
          allowClose: true,
          slots: [
            {
              name: 'title',
              content: i18n.t(`message.REQUEST_LIMIT_TITLE`)
            },
            {
              name: 'description',
              content: i18n.t(`message.REQUEST_LIMIT_DAY_DESCRIPTION`, { extraInfo: getDate(extraInfo.expiresAt) })
            }
          ],
          buttons: [
            {
              action: 'close',
              label: i18n.t(`message.REQUEST_LIMIT_BUTTON_LABEL`),
              type: 'button'
            }
          ]
        }),
        { root: true }
      )
    },

    [Errors.NON_ACTIVE_USER]: () => {
      router.push({ name: 'server-error', query: { code: Errors.NON_ACTIVE_USER } })
    },

    [Errors.OFFER_NO_LONGER_EXISTS]: ({ dispatch }) => {
      dispatch('modal/setModalDisplay', { state: true }, { root: true })
      dispatch(
        'modal/setModalComponent',
        getModalParam({
          allowClose: true,
          slots: [
            {
              name: 'title',
              content: i18n.t(`message.ORDER_ERROR_OVERSIZE_TITLE`)
            },
            {
              name: 'description',
              content: i18n.t(`message.ORDER_ERROR_OVERSIZE_TEXT`)
            }
          ],
          buttons: [
            {
              action: 'start',
              label: i18n.t(`message.ORDER_ERROR_OVERSIZE_BUTTON_REJECT`),
              type: 'button'
            }
          ]
        }),
        { root: true }
      )
    },

    [Errors.OFFER_PRICE_CHANGED]: ({ dispatch, state, getters }, extraInfo) => {
      dispatch(
        PUSH_AVAILABILITY_EVENT,
        {
          action: 'gasoleo - no disponible - buscador',
          label: 'no disponible timeout - cambio precio',
          availability: 'no disponible timeout - cambio precio',
          product: state.order.amountInfo.type
        },
        { root: true }
      )

      dispatch('setUnitPrice', extraInfo.currentPrice)
      dispatch('setTotalPrice', getters.getTotalPrice())
      dispatch('modal/setModalDisplay', { state: true }, { root: true })
      dispatch(
        'modal/setModalComponent',
        getModalParam({
          allowClose: true,
          slots: [
            {
              name: 'title',
              content: i18n.t(`message.ORDER_ERROR_PRICECHANGED_TITLE`)
            },
            {
              name: 'description',
              content: i18n.t(`message.ORDER_ERROR_PRICECHANGED_TEXT`, { extraInfo: getters.getTotalPrice() })
            }
          ],
          buttons: [
            {
              action: 'accept',
              label: i18n.t(`message.ORDER_ERROR_PRICECHANGED_BUTTON_ACCEPT`),
              type: 'button'
            },
            {
              action: 'reject',
              label: i18n.t(`message.ORDER_ERROR_PRICECHANGED_BUTTON_REJECT`),
              type: 'link'
            }
          ]
        }),
        { root: true }
      )

      LocalStorage.update('orderPending', {
        amountInfo: {
          unitPrice: extraInfo.currentPrice,
          totalPrice: getters.getTotalPrice()
        }
      })
    },

    [Errors.NOT_ENOUGH_LITERS_IN_OFFER]: ({ dispatch, state, getters }) => {
      dispatch(
        PUSH_AVAILABILITY_EVENT,
        {
          action: 'gasoleo - no disponible - checkout',
          label: 'no disponible timeout - litros',
          availability: 'no disponible timeout - litros',
          product: state.order.amountInfo.type
        },
        { root: true }
      )

      dispatch('modal/setModalDisplay', { state: true }, { root: true })
      dispatch(
        'modal/setModalComponent',
        getModalParam({
          allowClose: true,
          slots: [
            {
              name: 'title',
              content: i18n.t(`message.ORDER_ERROR_OVERSIZE_TITLE`)
            },
            {
              name: 'description',
              content: i18n.t(`message.ORDER_ERROR_OVERSIZE_TEXT`)
            }
          ],
          buttons: [
            {
              action: 'start',
              label: i18n.t(`message.ORDER_ERROR_OVERSIZE_BUTTON_REJECT`),
              type: 'button'
            }
          ]
        }),
        { root: true }
      )
    },

    [Errors.INVALID_ORDER_INPUT]: () =>
      router.push({ name: 'server-error', query: { code: Errors.Errors.INVALID_ORDER_INPUT } }),

    [Errors.NO_CURRENT_DRAFT]: () =>
      router.push({ name: 'server-error', query: { code: Errors.Errors.NO_CURRENT_DRAFT } }),

    [Errors.INVALID_DRAFT_INPUT]: () =>
      router.push({ name: 'server-error', query: { code: Errors.Errors.INVALID_DRAFT_INPUT } }),

    [Errors.INVALID_USER_SIGNATURE]: () =>
      router.push({ name: 'server-error', query: { code: Errors.INVALID_USER_SIGNATURE } }),

    [Errors.INVALID_OFFER_ID]: () => router.push({ name: 'server-error', query: { code: Errors.INVALID_OFFER_ID } }),

    [Errors.NO_PENDING_ORDERS]: () => scrollToTop('profile'),

    [Errors.MAX_CREATION_LIMIT]: ({ dispatch }, extraInfo) => {
      dispatch('modal/setModalDisplay', { state: true }, { root: true })
      dispatch(
        'modal/setModalComponent',
        getModalParam({
          allowClose: true,
          slots: [
            {
              name: 'title',
              content: i18n.t(`message.ORDER_LIMIT_TITLE`)
            },
            {
              name: 'description',
              content: i18n.t(`message.ORDER_LIMIT_DESCRIPTION`, { extraInfo: getDate(extraInfo.expiresAt) })
            }
          ],
          buttons: [
            {
              action: 'start',
              label: i18n.t(`message.ORDER_LIMIT_BUTTON_LABEL`),
              type: 'button'
            }
          ]
        }),
        { root: true }
      )
    },

    [Errors.INPUT_REQUIRED]: () => router.push({ name: 'server-error', query: { code: Errors.INPUT_REQUIRED } }),

    [Errors.INVALID_POSTAL_CODE]: ({ commit }) => commit('setPostalCodeValidation', { isValid: false }),

    setVoucher: ({ commit }, payload) => commit('setVoucher', payload),

    setVoucherValidation: ({ commit }, payload) => commit('setVoucherValidation', payload),

    resetCodeValidation: ({ commit }, params) => commit(`set${params.type}Validation`, { isValid: params.value }),

    setLockState: ({ commit }, payload) => commit('setLockState', payload),

    setResultsOut: ({ commit }, payload) => commit('setResultsOut', payload),

    setButtonState: ({ commit }, payload) => commit('setButtonState', payload),

    setVoucherDescription: ({ commit }, payload) => commit('setVoucherDescription', payload),

    [Errors.NO_VOUCHER]: ({ dispatch, state, getters }, extraInfo) => {
      dispatch('modal/setModalDisplay', { state: true }, { root: true })
      dispatch(
        'modal/setModalComponent',
        getModalParam({
          allowClose: true,
          icon: {
            name: 'IconTruck',
            size: 'big'
          },
          slots: [
            {
              name: 'title',
              content: i18n.t(`message.PROMOTIONAL_CODE_ERROR_MODAL_TITLE_NO_VOUCHER`)
            },
            {
              name: 'description',
              content: i18n.t(`message.PROMOTIONAL_CODE_ERROR_MODAL_TEXT_NO_VOUCHER`)
            },
            {
              name: 'help',
              content: i18n.t(`message.PROMOTIONAL_CODE_ERROR_MODAL_HELP_NO_VOUCHER`)
            }
          ],
          buttons: [
            {
              action: 'reject',
              label: i18n.t(`message.PROMOTIONAL_CODE_ERROR_BUTTON_REJECT`),
              type: 'button',
              isSecondary: true
            },
            {
              action: 'acceptCode',
              label: i18n.t(`message.PROMOTIONAL_CODE_ERROR_BUTTON_ACCEPT`),
              type: 'button'
            }
          ]
        }),
        { root: true }
      )
    },

    [Errors.INVALID_VOUCHER_USED_BY_USER]: ({ dispatch, state, getters }, extraInfo) => {
      dispatch('modal/setModalDisplay', { state: true }, { root: true })
      dispatch(
        'modal/setModalComponent',
        getModalParam({
          allowClose: true,
          icon: {
            name: 'IconTruck',
            size: 'big'
          },
          slots: [
            {
              name: 'title',
              content: i18n.t(`message.PROMOTIONAL_CODE_ERROR_MODAL_TITLE_INVALID_VOUCHER`)
            },
            {
              name: 'description',
              content: i18n.t(`message.PROMOTIONAL_CODE_ERROR_MODAL_TEXT_INVALID_VOUCHER`)
            },
            {
              name: 'help',
              content: i18n.t(`message.PROMOTIONAL_CODE_ERROR_MODAL_HELP_INVALID_VOUCHER`)
            }
          ],
          buttons: [
            {
              action: 'reject',
              label: i18n.t(`message.PROMOTIONAL_CODE_ERROR_BUTTON_REJECT`),
              type: 'button',
              isSecondary: true
            },
            {
              action: 'acceptCode',
              label: i18n.t(`message.PROMOTIONAL_CODE_ERROR_BUTTON_ACCEPT`),
              type: 'button'
            }
          ]
        }),
        { root: true }
      )
    },

    [Errors.INVALID_VOUCHER_MINIMUM_REQUIRED]: ({ dispatch, state, getters }, extraInfo) => {
      dispatch('modal/setModalDisplay', { state: true }, { root: true })
      dispatch(
        'modal/setModalComponent',
        getModalParam({
          allowClose: true,
          icon: {
            name: 'IconTruck',
            size: 'big'
          },
          slots: [
            {
              name: 'title',
              content: i18n.t(`message.PROMOTIONAL_CODE_ERROR_MODAL_TITLE_INVALID_QUANTITY_VOUCHER`)
            },
            {
              name: 'description',
              content: i18n.t(`message.PROMOTIONAL_CODE_ERROR_MODAL_TEXT_INVALID_QUANTITY_VOUCHER`)
            },
            {
              name: 'help',
              content: i18n.t(`message.PROMOTIONAL_CODE_ERROR_MODAL_HELP_INVALID_QUANTITY_VOUCHER`)
            }
          ],
          buttons: [
            {
              action: 'reject',
              label: i18n.t(`message.PROMOTIONAL_CODE_ERROR_BUTTON_REJECT`),
              type: 'button',
              isSecondary: true
            },
            {
              action: 'acceptCode',
              label: i18n.t(`message.PROMOTIONAL_CODE_ERROR_BUTTON_ACCEPT`),
              type: 'button'
            }
          ]
        }),
        { root: true }
      )
    },

    setVoucherUse: ({ commit }, payload) => commit('setVoucherUse', payload),

    setOrderFilter: ({ commit }, payload) => commit('setOrderFilter', payload),

    setFilterField: ({ commit }, payload) => commit('setFilterField', payload),

    setFilter: ({ commit }, payload) => commit('setFilter', payload),

    refreshData: ({ dispatch, state, getters }) => {
      dispatch(`orders/setButtonState`, { type: 'order', isDisabled: true }, { root: true })
      if (!state.order.addressInfo.postalCode.value) {
        dispatch(
          'orders/setAddress',
          {
            address: JSON.parse(localStorage.getItem('orderPending')).addressInfo.address,
            postalCode: JSON.parse(localStorage.getItem('orderPending')).addressInfo.postalCode
          },
          { root: true }
        )

        dispatch(
          'orders/setExtraAddress',
          {
            extraAddress: JSON.parse(localStorage.getItem('orderPending')).addressInfo.extraAddress
          },
          { root: true }
        )

        dispatch(
          'orders/setExtraInfo',
          {
            info:
              'additionalInfo' in JSON.parse(localStorage.getItem('orderPending'))
                ? JSON.parse(localStorage.getItem('orderPending')).additionalInfo.info
                : null
          },
          { root: true }
        )

        dispatch(
          'orders/setAmount',
          {
            id: JSON.parse(localStorage.getItem('orderPending')).amountInfo.id,
            unitPrice: JSON.parse(localStorage.getItem('orderPending')).amountInfo.unitPrice,
            totalPrice: JSON.parse(localStorage.getItem('orderPending')).amountInfo.totalPrice,
            type: JSON.parse(localStorage.getItem('orderPending')).amountInfo.type,
            isRecommended: JSON.parse(localStorage.getItem('orderPending')).amountInfo.isRecommended
          },
          { root: true }
        )

        dispatch(
          'orders/setQuantity',
          {
            quantity: JSON.parse(localStorage.getItem('orderPending')).amountInfo.quantity
          },
          { root: true }
        )

        dispatch(
          'orders/setDate',
          {
            date: JSON.parse(localStorage.getItem('orderPending')).timeInfo.date
          },
          { root: true }
        )

        dispatch(
          'orders/setTime',
          {
            time: JSON.parse(localStorage.getItem('orderPending')).timeInfo.time.toUpperCase()
          },
          { root: true }
        )

        dispatch(
          'orders/setPayment',
          {
            payment:
              'paymentInfo' in JSON.parse(localStorage.getItem('orderPending'))
                ? JSON.parse(localStorage.getItem('orderPending')).paymentInfo.payment.toUpperCase()
                : state.order.paymentInfo.payment
          },
          { root: true }
        )

        dispatch(
          'orders/setVoucher',
          {
            code:
              'code' in JSON.parse(localStorage.getItem('orderPending')).voucher
                ? JSON.parse(localStorage.getItem('orderPending')).voucher.code
                : null
          },
          { root: true }
        )

        dispatch(
          'orders/setVoucherValidation',
          {
            isValid:
              'isValid' in JSON.parse(localStorage.getItem('orderPending')).voucher
                ? JSON.parse(localStorage.getItem('orderPending')).voucher.isValid
                : null
          },
          { root: true }
        )

        dispatch(
          'orders/setVoucherDescription',
          {
            description:
              'description' in JSON.parse(localStorage.getItem('orderPending')).voucher
                ? JSON.parse(localStorage.getItem('orderPending')).voucher.description
                : null
          },
          { root: true }
        )

        dispatch(
          'orders/setFilterField',
          {
            filterField: JSON.parse(localStorage.getItem('orderPending')).amountInfo.filterField
          },
          { root: true }
        )

        dispatch(
          'orders/setOrderFilter',
          {
            value: JSON.parse(localStorage.getItem('orderPending')).amountInfo.filter
          },
          { root: true }
        )

        dispatch(
          'orders/setCardNumber',
          {
            number:
              'card' in JSON.parse(localStorage.getItem('orderPending'))
                ? JSON.parse(localStorage.getItem('orderPending')).card.number
                : null,
            control:
              'card' in JSON.parse(localStorage.getItem('orderPending'))
                ? JSON.parse(localStorage.getItem('orderPending')).card.control
                : null
          },
          { root: true }
        )

        dispatch(
          'orders/setVoucherType',
          {
            type:
              'voucher' in JSON.parse(localStorage.getItem('orderPending'))
                ? JSON.parse(localStorage.getItem('orderPending')).voucher.type
                : null
          },
          { root: true }
        )

        dispatch(
          'orders/setLegalBasisUrl',
          {
            legalBasisUrl:
              'legalBasisUrl' in JSON.parse(localStorage.getItem('orderPending')).voucher
                ? JSON.parse(localStorage.getItem('orderPending')).voucher.legalBasisUrl
                : null
          },
          { root: true }
        )

        dispatch('orders/setWayletAvailable', localStorage.getItem('wayletAvailable') === 'true', { root: true })
      }
    },

    setFilterChange: ({ commit }, payload) => commit('setFilterChange', payload),

    setVoucherError: ({ commit }, payload) => commit('setVoucherError', payload),

    getOrderHistory: ({ rootState }, payload) => {
      return Orders.getOrderHistory(payload)
        .then((res) => {
          return res.data
        })
        .catch((res) => {
          scrollToTop('server-error')
        })
    },

    getOrderHistoryHome: ({ rootState }, payload) => {
      return Orders.getOrderHistoryHome(payload, 'DELIVERED')
        .then((res) => {
          return res.data
        })
        .catch((res) => {
          scrollToTop('server-error')
        })
    },

    setHistory: ({ commit }, payload) => {
      commit('setHistory', payload)
    },

    setHistoryHome: ({ commit }, payload) => {
      commit('setHistoryHome', payload)
    },

    setCardNumber: ({ commit }, payload) => {
      commit('setCardNumber', payload)
    },

    setVoucherType: ({ commit }, payload) => commit('setVoucherType', payload),

    setDeleteReason: ({ commit }, payload) => {
      commit('setDeleteReason', payload)
    },

    setLegalBasisUrl: ({ commit }, payload) => {
      commit('setLegalBasisUrl', payload)
    },

    deleteUserOrder: ({ rootState }, payload) => {
      return Orders.deleteOrder(payload)
        .then((res) => {
          return res.data
        })
        .catch((res) => {
          scrollToTop('server-error')
        })
    },

    [Errors.CANCELLATION_REASON_MAX_LENGTH]: ({ dispatch, rootState }) => {
      dispatch(
        'modal/setModalProps',
        {
          props: cancelationFormValidation({ rootState: rootState, submitMessage: 'ORDER_HISTORY_FORM_FAILED_MESSAGE' })
        },
        { root: true }
      )
    },

    CANCELLATION_REQUIRE_INPUT: ({ dispatch, rootState }) => {
      dispatch(
        'modal/setModalProps',
        {
          props: cancelationFormValidation({
            rootState: rootState,
            submitMessage: 'ORDER_HISTORY_FORM_FAILED_REQUIRED_MESSAGE'
          })
        },
        { root: true }
      )
    },

    [Errors.CANCELLATION_REJECTED]: ({ dispatch, rootState }) => {
      dispatch('modal/setModalSize', { size: 430 }, { root: true })
      dispatch('modal/setModalSubmit', { submit: true }, { root: true })
      dispatch(
        'modal/setModalProps',
        { props: cancelationRejected({ rootState: rootState, submitMessage: 'ORDER_HISTORY_DELETE_STATE_FAILED' }) },
        { root: true }
      )
    },

    [Errors.CANCELLATION_REJECTED_BY_DATE]: ({ dispatch, rootState }) => {
      dispatch('modal/setModalSize', { size: 430 }, { root: true })
      dispatch('modal/setModalSubmit', { submit: true }, { root: true })
      dispatch(
        'modal/setModalProps',
        { props: cancelationRejected({ rootState: rootState, submitMessage: 'ORDER_HISTORY_DELETE_STATE_FAILED_BY_DATE' }) },
        { root: true }
      )
    },

    [Errors.ACTIVE_USER]: ({ dispatch, rootState }) => {
      dispatch('modal/setModalSize', { size: 430 }, { root: true })
      dispatch('modal/setModalSubmit', { submit: true }, { root: true })
      dispatch(
        'modal/setModalProps',
        { props: cancelationRejected({ rootState: rootState, submitMessage: 'ORDER_HISTORY_DELETE_STATE_FAILED' }) },
        { root: true }
      )
    }
  },

  getters: {
    getResumeItem: (state) => (item) => {
      return state.order[item]
    },

    getExtraInfo: (state) => {
      return state.order.additionalInfo.info
    },

    getTotalPrice: (state) => (unitPrice, amount) => {
      const price = unitPrice || state.order.amountInfo.unitPrice
      const requestAmount = amount || state.order.amountInfo.quantity
      const total = price * parseInt(requestAmount)
      const rounded = Math.round(total * 100) / 100
      return rounded
    },

    getHistoryFiltered: (state) => (filter) => {
      if (filter) {
        const result =
          filter === 'ALL'
            ? state.history.response
            : state.history.response.filter((node) => node.order.status === filter)
        return result
      }
    },

    getHistoryHome: (state) => () => {
      return state.historyHome.response
    },

    getCardNumber: (state) => {
      return state.order.card.number
    }
  }
}
