<template>
  <section class="order-recap">
    <form
      @submit.prevent="sendRequest"
      class="order-recap__form"
      autocomplete="off"
    >
      <section class="order-recap__entry">
        <template v-for="(node, key) in orderResume">
          <article
            v-if="!exclude.includes(key)"
            :key="key"
            class="order-recap__resume"
          >
            <!-- START: INFO RECAP -->
            <OrderResumeInfo>
              <template #title>{{
                $t(`message.RESUME_TITLE_${key.toUpperCase()}`)
              }}</template>
              <template
                slot="content"
                v-for="(slot, value) in orderResumeItem(key)"
              >
                <div
                  :key="value"
                  class="order-info__line"
                  v-if="key != 'additionalInfo'"
                >
                  <ul :class="[`order-info__row--${value}`, 'order-info__row']">
                    <li>
                      {{ $t(`message.RESUME_STORE_${value.toUpperCase()}`) }}
                    </li>
                    <li>{{ getSlotContent(value, slot) }}</li>
                  </ul>
                </div>

                <div v-else :key="value">
                  <aside class="extraDeliveryInfo">
                    <span>{{ $t(`message.RESUME_STORE_ADDITIONALINFO`) }}</span>
                    <FormField idName="input-extraInfo">
                      <template #input>
                        <input
                          type="text"
                          id="extraInfo"
                          :placeholder="
                            $t(`message.LANDING_INPUT_EXTRAINFO_PLACEHOLDER`)
                          "
                          maxlength="255"
                          v-model.trim="$v.form.extraDeliveryInfo.$model"
                        />
                      </template>
                    </FormField>
                    <p class="order-info__additionalText">
                      {{ $t(`message.ORDER_DELIVERY_EXTRATEXT`) }}
                    </p>
                  </aside>
                </div>
              </template>
              <template
                #paymentCard
                v-if="checkValueInObject(orderResumeItem(key), 'CARD')"
              >
                <PaymentCard :cards="['visa', 'master']">
                  <template #text>{{
                    $t(`message.ORDER_SUMMARY_PAYMENT_AVAILABLE_CREDIT_CARD`)
                  }}</template>
                  <template #extra>
                    <CognitiveMessage
                      :message="$t(`message.COGNITIVE_PAYMENT_MESSAGE`)"
                      display="secondary"
                    />
                  </template>
                </PaymentCard>
              </template>
            </OrderResumeInfo>
            <!-- END: INFO RECAP -->
          </article>
        </template>
        <!-- START: TRAVEL CARD -->
        <article
          v-if="voucherTravelType && voucherValidation"
          class="order-recap__resume"
        >
          <OrderResumeInfo>
            <template #title>{{
              $t(`message.ORDER_SUMMARY_TRAVEL_CARD_TITLE`)
            }}</template>
            <template #content>
              <aside class="cardNumber">
                <div class="cardNumber__fields">
                  <FormField class="promoCard__prefix">
                    <template #input>
                      <input
                        type="text"
                        id="promoCard__prefix"
                        :value="$v.notRequired.promoCardPrefix.$model"
                        disabled
                        data-cy="travel-prefix"
                      />
                    </template>
                  </FormField>
                  <FormField class="promoCard__number">
                    <template #input>
                      <input
                        type="text"
                        id="promoCard__number"
                        placeholder="123456789"
                        maxlength="9"
                        v-model.trim="$v.notRequired.promoCard.$model"
                        v-numericOnly="true"
                        data-cy="travel-number"
                      />
                    </template>
                    <template v-if="$v.notRequired.promoCard.$error" #error>
                      {{ $t(`message.ORDER_SUMMARY_PROMOTIONALCARD_ERROR`) }}
                    </template>
                  </FormField>
                  <FormField class="promoCard__control">
                    <template #input>
                      <input
                        type="text"
                        id="promoCard__control"
                        maxlength="1"
                        placeholder="0"
                        v-model.trim="$v.notRequired.promoCardControl.$model"
                        v-numericOnly="true"
                        data-cy="travel-control"
                      />
                    </template>
                    <template
                      v-if="$v.notRequired.promoCardControl.$error"
                      #error
                    >
                      {{
                        $t(`message.ORDER_SUMMARY_PROMOTIONALCARDCONTROL_ERROR`)
                      }}
                    </template>
                  </FormField>
                </div>
              </aside>
            </template>
          </OrderResumeInfo>
        </article>
        <!-- STOP: TRAVEL CARD -->
      </section>

      <!-- START: PRICE RECAP -->
      <section class="order-recap__entry" id="order-prices">
        <article class="order-block">
          <OrderPricesInfo :totalPrice="formatPrice">
            <template #title>{{
              $t(`message.RESUME_TITLE_AMOUNTINFO`)
            }}</template>
            <template #cognitive>
              <CognitiveMessage
                :message="$t(`message.COGNITIVE_MESSAGE_RESUME`)"
              />
            </template>
            <template #labelType>{{
              $t(`message.RESUME_STORE_TYPE`)
            }}</template>
            <template #imageType v-if="orders.order.amountInfo.type"
              ><img
                :src="
                  require(`images/png/logos/${
                    orders.order.amountInfo.type
                  }.png`)
                "
                :alt="orders.order.amountInfo.type"
            /></template>

            <template #labelQuantity>{{
              $t(`message.RESUME_STORE_QUANTITY`)
            }}</template>
            <template #amountQuantity>{{
              $t(`message.RESUME_STORE_QUANTITY_TYPE`, {
                ltr: orders.order.amountInfo.quantity
              })
            }}</template>
            <template #labelTotalPrice>{{
              $t(`message.RESUME_STORE_TOTALPRICE`)
            }}</template>
            <template #amountUnitPrice>{{
              $t(`message.RESUME_STORE_UNITPRICE`, { chr: unitPrice })
            }}</template>
            <template #labelIva>{{ $t(`message.RESULT_IVA_LABEL`) }}</template>
            <template #promotionalCode v-if="voucherUse">
              <PromotionMessageValidation
                :showInfo="promoLegalBasisUrl"
                @displayInfo="showPromoInfo"
                valid
              >
                <template #message
                  >{{
                    $t(`message.PROMOTIONAL_CODE_VALID`, {
                      promotionalCode: voucherCode
                    })
                  }}
                  {{ voucherDescription }}</template
                >
              </PromotionMessageValidation>
            </template>
          </OrderPricesInfo>
        </article>
      </section>
      <!-- END: PRICE RECAP -->
      <h3 class="order-recap__message">
        {{ $t(`message.RECAP_CONFIRMACION_PEDIDO`) }}<span>{{ orderID }}</span>
      </h3>
      <h3 v-if="checkProductType()" class="order-recap__message">
        {{ $t(`message.RECAP_CONFIRMACION_DESCONTO`)
        }}<span>{{ orderID }}</span>
      </h3>

      <!-- START: TERMS -->
      <CheckBoxComponent
        id="terms"
        :isChecked="form.isTermsAccepted"
        :hasError="$v.form.isTermsAccepted.$error"
        @input="setTermsValue($event)"
      >
        <template #label>
          {{ $t(`message.RESUME_TERMS`) }}
          <router-link
            class="link--simple"
            to="/condiciones-de-servicio"
            target="_blank"
            >{{ $t(`message.RESUME_TERMS_LINK`) }}</router-link
          >
        </template>
        <template #errorMessage>{{ $t(`message.REQUIRED_LABEL`) }}</template>
      </CheckBoxComponent>
      <!-- END: TERMS -->

      <!-- START: ACTION BUTTONS -->
      <footer class="order-recap__footer">
        <a class="link--simple link--simple--hasbackArrow" @click="goBack()">
          {{ $t(`message.RESUME_BACK_LABEL`) }}
        </a>
        <ButtonComponent
          :spinner="isLoading"
          :label="$t(`message.RESUME_BUTTON_LABEL`)"
          type="submit"
          :isDisabled="buttonDisabledState"
        />
      </footer>
      <!-- END: ACTION BUTTONS -->
    </form>
  </section>
</template>
<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import { GigyaService } from '../../services/gigya'
import { Errors } from '../../errors'
import { LocalStorage } from '../../services/storage/localStorage'
import { Draft } from '../../helpers/draft'
import { Constants } from '../../constants'
import { maxLength, minLength } from 'vuelidate/lib/validators'

export default {
  name: 'OrderRecap',
  data() {
    return {
      backRoute: '',
      termsStatus: false,
      currentPrice: '',
      isLoading: false,
      exclude: [
        'ID',
        'amountInfo',
        'provider',
        'lockState',
        'voucher',
        'resultsOut',
        'buttonState',
        'isFilterChange',
        'deletedReason',
        'card'
      ],
      form: {
        extraDeliveryInfo: { maxLength: maxLength(255) },
        isTermsAccepted: false
      },
      notRequired: {
        promoCardPrefix: Constants.PROMOTIONCARD_PREFIX,
        promoCard: '',
        promoCardControl: ''
      }
    }
  },

  validations: {
    form: {
      extraDeliveryInfo: {},
      isTermsAccepted: {
        checked(val) {
          return val
        }
      }
    },
    notRequired: {
      promoCardPrefix: {},
      promoCard: {
        min: (value, model) =>
          value === '' ? false : minLength(Constants.MIN_CARD_ACCEPTED)(value)
      },
      promoCardControl: {
        min: (value, model) =>
          value === ''
            ? false
            : minLength(Constants.MIN_CARDCONTROL_ACCEPTED)(value)
      }
    }
  },

  watch: {
    unitPrice() {
      return this.unitPrice
    },

    additionaDeliverylInfo() {
      this.setExtraInfo({ info: this.$v.form.extraDeliveryInfo.$model })
      LocalStorage.update('orderPending', {
        additionalInfo: {
          info: this.$v.form.extraDeliveryInfo.$model
        }
      })
    },

    buttonDisabledState() {
      this.isLoading = this.buttonDisabledState
    },

    cardNumber() {
      if (this.cardValidation) {
        this.setCardNumber({
          number: this.$v.notRequired.promoCard.$model,
          control: this.$v.notRequired.promoCardControl.$model
        })
        LocalStorage.update('orderPending', {
          card: {
            type: 'TRAVEL_CLUB',
            number: this.$v.notRequired.promoCard.$model,
            control: this.$v.notRequired.promoCardControl.$model
          }
        })
      } else {
        const storage = JSON.parse(localStorage.getItem('orderPending'))
        if ('card' in storage) delete storage.card
        localStorage.setItem('orderPending', JSON.stringify(storage))
        this.setCardNumber({
          number: undefined,
          control: undefined
        })
      }
    }
  },

  computed: {
    ...mapState(['orders', 'user']),
    ...mapGetters({
      orderResumeItem: 'orders/getResumeItem',
      extraInfo: 'orders/getExtraInfo',
      getCardNumber: 'orders/getCardNumber'
    }),

    orderResume() {
      return this.orders.order
    },

    unitPrice() {
      return parseFloat(
        this.orders.order.amountInfo.unitPrice
      ).toLocaleString()
    },

    quantity() {
      return this.orders.order.amountInfo.quantity
    },

    additionaDeliverylInfo() {
      return this.form.extraDeliveryInfo
    },

    voucherValidation() {
      return this.orders.order.voucher.isValid
    },

    voucherDescription() {
      return this.orders.order.voucher.description
    },

    voucherCode() {
      return this.orders.order.voucher.code
    },

    buttonDisabledState() {
      return this.orders.order.buttonState.order.isDisabled
    },

    voucherUse() {
      return this.orders.order.voucher.canUseIt
    },

    isFilterByEuros() {
      return this.orders.order.amountInfo.filter === 'EUROS'
    },

    totalPrice() {
      return this.orders.order.amountInfo.totalPrice
    },

    formatPrice() {
      const total =
        typeof this.totalPrice === 'string'
          ? this.totalPrice
          : this.totalPrice.toFixed(2)
      const res = total.split('.')
      const integer = parseFloat(res[0]).toLocaleString()
      const decimal = res[1]
      return `${integer}<span>,${decimal}€</span>`
    },

    literForEuros() {
      const actualLiters = Math.floor(
        this.quantity / this.orders.order.amountInfo.unitPrice
      )
      if (actualLiters > Constants.MAX_ACCEPTED) {
        return Constants.MAX_ACCEPTED
      } else if (actualLiters < Constants.MIN_ACCEPTED) {
        return Constants.MIN_ACCEPTED
      } else {
        return actualLiters
      }
    },

    totalPriceEuros() {
      return (
        this.literForEuros * this.orders.order.amountInfo.unitPrice
      ).toFixed(2)
    },

    cardValidation() {
      const promoCard =
        this.$v.notRequired.promoCard.$model !== '' &&
        this.$v.notRequired.promoCard.$model !== undefined
      const promoCardControl =
        this.$v.notRequired.promoCardControl.$model !== '' &&
        this.$v.notRequired.promoCardControl.$model !== undefined
      const promoCardError = this.$v.notRequired.promoCard.$error
      const promoCardControlError = this.$v.notRequired.promoCardControl.$error
      return (
        !promoCardError &&
        !promoCardControlError &&
        promoCard &&
        promoCardControl
      )
    },

    cardNumber() {
      return {
        number: this.notRequired.promoCard,
        control: this.notRequired.promoCardControl
      }
    },

    cardType() {
      return this.orders.order.card.type
    },

    voucherTravelType() {
      return this.orders.order.voucher.type === 'TRAVEL_CLUB'
    },

    promoLegalBasisUrl() {
      return this.orders.order.voucher.legalBasisUrl !== ''
        ? this.orders.order.voucher.legalBasisUrl
        : undefined
    }
  },

  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.backRoute = from.name
      vm.pushEcommerceView({ stepNumber: 3 })
    })
  },

  methods: {
    ...mapActions('orders', [
      'setOrder',
      'setUnitPrice',
      'setExtraInfo',
      'setID',
      'setProvider',
      'setMemberGetMemberCode',
      'setOrderDraft',
      'setButtonState',
      'setVoucherUse',
      'setCardNumber'
    ]),
    ...mapActions('modal', ['setModalDisplay', 'setModalComponent']),
    ...mapActions('analytics', [
      'pushEcommerceView',
      'pushEcommerceOption',
      'pushAvailabilityEvent',
      'tagPage'
    ]),
    checkProductType() {
      if (
        this.orders.order.amountInfo.type === 'AgroDiesel' ||
        this.orders.order.amountInfo.type === 'BiEnergy'
      ) {
        return true
      } else {
        return false
      }
    },
    sendRequest() {
      const store = this.$store
      store.dispatch(`orders/setButtonState`, {
        type: 'order',
        isDisabled: true
      })
      this.$v.form.$touch()
      const invalid = this.$v.form.$invalid
      if (!invalid) {
        GigyaService.getAccountInfo().then(eventObject => {
          if (eventObject.errorCode !== 0) {
            this.setModalDisplay({ state: true })
            this.setModalComponent({
              name: 'UserSession',
              allowClose: false,
              size: 970,
              props: {
                hasDestination: 'modal',
                isComponent: 'UserLogin'
              }
            })
          } else {
            this.setOrderRequest(eventObject)
          }
        })
      } else {
        this.termsStatus = true
        this.isLoading = false
        store.dispatch(`orders/setButtonState`, {
          type: 'order',
          isDisabled: false
        })
      }
    },

    async setOrderRequest({ UID, UIDSignature, signatureTimestamp }) {
      this.isLoading = true
      const {
        amountInfo: { ID, unitPrice, quantity },
        addressInfo: { address, postalCode, extraAddress },
        paymentInfo: { payment },
        timeInfo: { time },
        voucher: { code, description, type }
      } = this.orders.order

      const card = this.cardValidation
        ? {
          type: this.cardType,
          number: `${this.notRequired.promoCardPrefix}${
            this.notRequired.promoCard
          }${this.notRequired.promoCardControl}`
        }
        : undefined
      const voucher = code !== '' ? { code, description, type } : undefined
      const additionalInfo =
        this.$v.form.extraDeliveryInfo.$model !== ''
          ? this.$v.form.extraDeliveryInfo.$model
          : undefined
      let lang = ''
      if (this.$i18n.locale === 'es') {
        lang = 'BP5_ES'
      } else {
        lang = 'BP5_EN'
      }
      const payload = {
        offerId: ID,
        address,
        additionalAddress: extraAddress,
        postalCode: postalCode.value,
        unitPrice,
        amount: quantity,
        filter: 'LITERS',
        timeSlot: time.toUpperCase(),
        paymentOption: payment.toUpperCase(),
        additionalInfo,
        voucher,
        card,
        lang
      }

      this.pushEcommerceOption({
        stepNumber: 3,
        option:
          payment === 'CASH'
            ? 'efectivo'
            : payment === 'WAYLET'
              ? 'waylet'
              : 'tarjeta',
        extraParams: {
          franjaHoraria: time === 'MORNING' ? 'manana' : 'tarde'
        }
      })

      if (card && card.number) this.setCardNumber({ number: card.number })

      const order = await this.setOrder({
        UID,
        UIDSignature,
        signatureTimestamp,
        ...payload
      })
      if (order) this.setRoute(order)
    },

    setRoute(obj) {
      this.isLoading = this.buttonDisabledState

      if (typeof obj !== 'object') {
        this.$store.dispatch(`orders/${Errors.SERVER_ERROR}`)
        return false
      }

      if ('code' in obj) {
        const extraInfo = 'extraInfo' in obj ? obj.extraInfo : ''
        this.$store.dispatch(`orders/${obj.code}`, extraInfo)
      } else {
        const orderID = obj.order.id
        this.setID(orderID)
        this.setProvider(obj.provider)
        this.setMemberGetMemberCode(obj.user.memberGetMemberCode)

        this.tagPage({
          page: 'tagOrder',
          extraParams: {
            postalCode: obj.order.postalCode.value,
            totalPrice: obj.order.total,
            unitPrice: obj.order.unitPrice,
            litersAmount: obj.order.amount,
            productType: obj.order.productType
          }
        })

        this.$router.push({ name: 'completed' })
      }
    },

    getSlotContent(index, type) {
      if (index !== 'payment' && index !== 'time') {
        return typeof type !== 'object' ? type : type.value
      } else {
        return this.$t(
          `message.RESUME_STORE_${index.toUpperCase()}_${type.toUpperCase()}`
        )
      }
    },

    goBack() {
      this.$router.push({ name: 'summary' })
    },

    changeUnitPrice() {
      this.setUnitPrice(this.currentPrice)
    },

    checkValueInObject(obj, value) {
      return Object.keys(obj).some(k => {
        return obj[k] === value
      })
    },

    setTermsValue($event) {
      this.form.isTermsAccepted = $event
    },

    correctAmountByEuros(value) {
      if (parseInt(value) < Constants.MIN_ACCEPTED) {
        return Constants.MIN_ACCEPTED
      } else if (parseInt(value) > Constants.MAX_ACCEPTED) {
        return Constants.MAX_ACCEPTED
      } else {
        return value
      }
    },

    openBasicModal(values = { props: {}, slots: [] }) {
      const payload = {
        state: true,
        name: 'ModalBasic',
        allowClose: false,
        size: 490,
        props: values.props,
        slots: values.slots
      }

      this.setModalDisplay(payload)
      this.setModalComponent(payload)
    },

    showPromoInfo() {
      const values = {
        slots: [
          {
            name: 'descriptionLiters',
            content: `<div class='modal-basic__text'>${this.$t(
              `message.PROMO_LEGAL_BASIS`,
              { url: `${this.promoLegalBasisUrl}` }
            )}</div>`
          }
        ]
      }

      this.openBasicModal(values)
    }
  },

  beforeCreate() {
    if (!window.Cypress) this.$store.dispatch('orders/refreshData')
  },

  mounted() {
    this.$root.$on('changeUnitPrice', () => this.changeUnitPrice())
    this.isLoading = true
    if (!window.Cypress) {
      GigyaService.getAccountInfo().then(eventObject => {
        if (eventObject.errorCode !== 0) {
          this.setModalDisplay({ state: true })
          this.setModalComponent({
            name: 'UserSession',
            allowClose: false,
            size: 970,
            props: {
              hasDestination: 'modal',
              isComponent: 'UserLogin'
            }
          })
        } else {
          this.form.extraDeliveryInfo = this.orders.order.additionalInfo.info
          this.notRequired.promoCard = this.orders.order.card.number
          this.notRequired.promoCardControl = this.orders.order.card.control

          const that = this
          const store = this.$store
          Draft.set(eventObject).then(function(res) {
            if (res) {
              if (res.code) {
                const extraInfo = 'extraInfo' in res ? res.extraInfo : ''
                store.dispatch(`orders/${res.code}`, extraInfo)

                return false
              } else {
                store.dispatch(`orders/setButtonState`, {
                  type: 'order',
                  isDisabled: false
                })
                if (that.voucherValidation) {
                  store.dispatch(`orders/setVoucherUse`, { use: true })
                }
              }
            }
          })
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped src="./OrderRecap.scss"></style>
